export class MenuBrandModel {
    id: number;
    menu_brand_name: string;
    description: number;
    status: number;
    created_date: string;
    updated_date: string;
    name : string;
}

export class MenuBrandTemplateModel {
    id: number;
    menu_brand_id: number;
    menu_template_size_id: number;
    status: number;
    cost_per_page: number;
    template_images: MenuTemplateImage[];
}

export class MenuTemplateImage {
    id: number;
    menu_brand_id: number;
    menu_template_size_id: number;
    menu_template_image: any;
    description: string;
    status: number;

}

export class MenuTemplate {
    menu_template_size_id: number;
    menu_template_name: string;
    name: string;
    id : number;
}

export class MenuBrandChannelModel {
    menu_brand_id: number;
    channels: number;
    status: boolean;
}

export class MenuBrandChannel {
    menu_brand_id: number;
    channels: any[];
}




/******************STATUS***************** */

/*

menu_template_request.status
0 - Open
1 - Closed';

menu_template_request.internal_status

1 - Pending Review By TSE
2 - Sent to Agency
3 - Updated by Agency
4 - Approved
5 - Rejected

menu_template_request.request_role

1 - Outlet
2 - TSE
3 - Agency

menu_template_request.updated_request_role

1 - Outlet
2 - TSE
3 - Agency

*/

export class MenuTemplateRequestModel {
    id: number;
    ro_name: string;
    ro_code: string;
    tse_user_id: number;
    outlet_user_id: number;
    tse_name: string;
    assigned_user_name: string;
    assigned_record_id: number;
    assigned_user_id: number;
    assigned_date: string;
    internal_status: number;
    menu_brand_id: number;
    request_role: number;
    updated_request_role: number;
    no_of_copies: number;
    menu_brand_name: string;
    request_status: string;
    request_timestamp: string;
    region_id: number;
    state_id: number;
    state_name: string;
    region_name: string;
    internalStatusName: string;
    showView: boolean;
    showAssign: boolean;
    template_id: number;
    menu_template_name: string;
    boldstatus: boolean;
    newStatus: boolean;
    cost_per_page: number;
    no_of_pages: number;
    total_cost: number;
}


export class MenuTemplateSizeModel {
    id: number;
    menu_template_name: string;
    description: string;
    status: number;
}

export class MenuTransactionImageModel {
    id: number;
    menu_template_request_id: number;
    menu_template_transaction_id: number;
    menu_template_image: number;
    page_no: number;
}

/*********Menu Transaction Model*********/
/*

menu_template_transactions.status

1 - Pending Review By TSE
2 - Sent to Agency
3 - Updated by Agency
4 - Approved
5 - Rejected

menu_template_transactions.request_role

1 - Outlet
2 - TSE
3 - Agency

*/

export class MenuTransactionModel {
    ro_name: string;
  ro_code : number;
  tse_user_id : number;
  outlet_user_id : number;
  tse_name : string;
  assigned_user_name : string;
  assigned_user_id : number;
  assigned_date : string;
  template_logo : string;
  logo_name : string;
  created_by : string;
  menu_brand_name : string;
  region_id : number;
  no_of_copies : number;
  region_name : string;
  menu_template_name : string;
  menu_template_request_id : number;
  menu_template_transaction_id : number;
  user_comments : string;
  request_role : number;
  status : number;
  menu_brand_id: number;
  template_images : TemplateImageModel[];
  menu_template_id : number;
  menu_request_id : number;
  request_timestamp : string;
  created_date: string;
  state_name: string;
  tse_mobile_number: string;
  menu_folder_name: string;
}

export class TemplateImageModel {
    menu_template_image : any;
  page_no: number;
  image_name : any;
  folder_name : any;
}

export class StateModel {
    id: number;
    state_code: number;
    state_name: string;
    region_id: number;
    name : string;
    state_year : number;
}

export class RegionModel {
    id: number;
    region_name: string;
    status: number;
}

