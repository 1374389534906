import { Injectable } from "@angular/core";
import { RegionDataService } from "src/app/model/region-data.service";
import {
  MenuTemplate,
  MenuBrandModel,
  MenuTemplateRequestModel,
  StateModel,
  RegionModel,
  MenuTemplateSizeModel,
  MenuBrandTemplateModel,
  MenuBrandChannelModel,
} from "src/app/model/contract/menu-program";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class MenuProgramProvider {
  templates: MenuTemplate[];
  templates1: MenuTemplate[];
  tempalteConfig: MenuBrandTemplateModel[];
  brands: MenuBrandModel[];
  brandChannel: MenuBrandChannelModel[];
  request: MenuTemplateRequestModel[];
  searchRequestData: MenuTemplateRequestModel[];
  userId: any;
  totalCost: number = 0;
  public states: StateModel[];
  public regions: RegionModel[];

  constructor(
    public usersService: RegionDataService,
    public toasterService: ToastrService
  ) {}

  getTemplateList(): void {
    this.usersService.getAllTemplate().subscribe((data) => {
      this.templates = data;
      if (this.templates.length > 0) {
        this.templates.forEach((b) => {
          b.id = b.menu_template_size_id;
          b.name = b.menu_template_name;
        });
      }
    });
  }

  getMenuTemplateList(): void {
    this.usersService.getAllMenuTemplates().subscribe((data) => {
      this.templates1 = data;
    });
  }

  getMenuTemplateConfig(): void {
    this.usersService.getAllMenuTemplatesConfig().subscribe((data) => {
      this.tempalteConfig = data;
    });
  }

  addBrandLoader: boolean;
  addMenuBrandTemplateConfig(data: MenuBrandTemplateModel): void {
    this.addBrandLoader = false;
    this.usersService.addBrandMenuTemplate(data).subscribe((data) => {
      this.tempalteConfig = data;
      this.addBrandLoader = true;
      this.toasterService.success("Added Successfully", "Success", {
        timeOut: 1000,
      });
    });
  }

  getBrandList(): void {
    this.usersService.getAllBrands().subscribe((data) => {
      this.brands = data;
      if (this.brands.length > 0) {
        this.brands.forEach((b) => {
          b.id = b.id;
          b.name = b.menu_brand_name;
        });
      }
    });
  }

  getMenuTemplateRequestList(): void {
    let calendar_month = parseInt(localStorage.getItem("calendar_year"));
    this.usersService.getAllTemplateMenuRequest(calendar_month).subscribe((data) => {
      this.request = data;
      this.searchRequestData = data;
      this.totalCost = this.searchRequestData
        .filter((r) => r.total_cost && r.internal_status == 4)
        .reduce((a, b) => a + b.total_cost || 0, 0);
      if (this.request.length > 0) {
        this.request.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
        this.searchRequestData.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
      }
    });
  }

  addMenuBrand(data: MenuBrandModel) {
    this.usersService.addMenuBrand(data).subscribe((data) => {
      this.brands = data;
    });
  }

  channelLoader: Boolean;

  getAllMenuBrandChannel() {
    this.channelLoader = false;
    this.usersService.getAllMenuBrandChannel().subscribe((data) => {
      this.channelLoader = true;
      this.brandChannel = data;
      if (this.brandChannel.length > 0) {
        this.brandChannel.forEach((b) => {
          b.status = true;
        });
      }
    });
  }

  addMenuTemplate(data: MenuTemplateSizeModel) {
    this.usersService.addMenuTemplate(data).subscribe((data) => {
      this.templates = data;
    });
  }

  addMenuBrandChannel(data) {
    this.usersService.addMenuBrandChannel(data).subscribe((data) => {
      this.getAllMenuBrandChannel();
    });
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe((data) => {
      this.states = data;
    });
  }

  getRegionList(): void {
    this.usersService.getAllRegions().subscribe((data) => {
      this.regions = data;
    });
  }

  searchMenuRecords(brandId, templateName, internalStatus, region, state) {
    if (typeof brandId != "undefined") {
      if (brandId != null && brandId != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (f) => { return brandId.includes(f.menu_brand_id)}
        );
        console.log(this.searchRequestData);
      }
    }

    if (typeof templateName != "undefined") {
      if (templateName != null && templateName != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (r) => r.template_id == templateName
        );
      }
    }

    if (internalStatus != "") {
      this.searchRequestData = this.searchRequestData.filter(
        (r) => r.internal_status == internalStatus
      );
    }

    if (typeof region != "undefined") {
      if (region != null && region != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (r) => r.region_id == region
        );
      }
    }

    if (typeof state != "undefined") {
      if (state != null && state != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (r) => r.state_id == state
        );
      }
    }
  }

  searchMenuRecordsWithDate(
    brandId,
    templateName,
    internalStatus,
    date1,
    date2,
    region,
    state
  ) {
    let tempDate = new Date(date2);
    console.log("here");
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    if (typeof brandId != "undefined" && brandId != "") {
      if (brandId != 0) {
        this.searchRequestData = this.searchRequestData.filter(
          (m) =>
            m.menu_brand_id == brandId &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
        );
      }
    }

    if (typeof templateName != "undefined") {
      if (templateName != 0 && templateName != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (m) =>
            m.menu_template_name == templateName &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
        );
      }
    }

    if (internalStatus != "") {
      this.searchRequestData = this.searchRequestData.filter(
        (m) =>
          m.internal_status == internalStatus &&
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
      );
    }

    if (typeof region != "undefined") {
      if (region != 0 && region != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (m) =>
            m.region_id == region &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
        );
      }
    }

    if (typeof state != "undefined") {
      if (state != 0 && state != "") {
        this.searchRequestData = this.searchRequestData.filter(
          (m) =>
            m.state_id == state &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
        );
      }
    }
  }

  getDateBasedRecords(date1, date2) {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.request = this.request.filter(
      (m) =>
        new Date(m.request_timestamp) >= new Date(date1) &&
        new Date(m.request_timestamp) <= date2
    );
    this.searchRequestData = this.request;
  }
}
