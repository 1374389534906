import { Component, Input, OnInit } from "@angular/core";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { DatePipe } from "@angular/common";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { StateModel } from "src/app/model/contract/menu-program";
import {
  ChannelModel,
  FrequencyModel,
  RegionModel,
  ChartModel,
} from "src/app/model/contract/channel";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { GeneralService } from "../Services/general.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AlertService } from "src/app/shared/Providers/RouterProvider/AlertProvider";
import {
  DROPDOWN_DETAILS,
  CHANNEL_TARGET,
  CHANNEL_BUDGET,
  CHANNEL_TARGET_UPLIFT,
  DISTRIBUTION_HEADERS,
  SCHEMES_HEADERS,
  ROI_HEADERS,
  M1_HEADERS,
  M2_HEADERS,
  M3_HEADERS,
  COLOR_TITLE,
  DROPDOWN_DETAILS1,
  CATEGORY_BUDGET,
  CHANNEL_TARGET_UPLIFT_ACHIEVEMENT,
  CHANNEL_TARGETS,
} from "../Models/general-headings.model";
import { CommonHttpServiceService } from "../Services/common-http-service.service";
import { TileModel } from "../Models/tile_data.model";
import { ToastrService } from "ngx-toastr";
import { SettingDataService } from "src/app/model/setting-data.service";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { RegionDataService } from "src/app/model/region-data.service";
import { FilterModel } from "../Models/filter-data";
declare var $: any;
@Component({
  selector: "app-kingservice",
  templateUrl: "./kingservice.component.html",
  styleUrls: ["./kingservice.component.scss"],
})
export class KingserviceComponent implements OnInit {
  dateRange: any = [];

  dropdown: any[] = DROPDOWN_DETAILS1;
  data: any;
  whitelist_date1: any;
  whitelist_date: any = 0;

  min: number;
  max: number;
  smonth: any;
  sdate: any;
  options: Array<any> = [];
  maxDate: Date;

  kingService = [
    {
      name: "Target",
      data: "0",
      type: "",
    },
    {
      name: "Achievement",
      data: "0",
      type: "",
    },
    {
      name: "Achievement Percentage",
      data: "0 %",
      type: "",
    },
    {
      name: "% Of CSM's achieving target",
      data: "0",
      type: "",
    },
    {
      name: "HE mix",
      data: "0 %",
      type: "",
    },
    {
      name: "Uplift",
      data: "0 %",
      type: "",
    },
    {
      name: "Core Uplift",
      data: "0 %",
      type: "",
    },
    {
      name: "Premium Uplift",
      data: "0 %",
      type: "",
    },
    {
      name: "Beck's Uplift",
      data: "0 %",
      type: "",
    },
    {
      name: "IPB Uplift",
      data: "0 %",
      type: "",
    },
    // {
    //   name: "ROI",
    //   data: "0 %",
    //   type: "",
    // },
    {
      name: "Total Points Earned",
      data: "0%",
      type: "",
    },
    {
      name: "Total value Earned (INR)",
      data: "0",
      type: "",
    },
    {
      name: "Total Points Budget",
      data: "0%",
      type: "",
    },
    {
      name: "Total Value Budget(INR)",
      data: "0",
      type: "",
    },
  ];

  kingServiceHeader = ["Target", "Achievement", "Percentage"];

  //table headers
  channelTargetHeaders: any[] = CHANNEL_TARGETS;
  channelTargetHeader: any[] = CHANNEL_TARGET;
  channelBudgetHeader: any[] = CHANNEL_BUDGET;
  categoryBudgetHeader: any[] = CATEGORY_BUDGET;
  channeUpliftHeader: any[] = CHANNEL_TARGET_UPLIFT;
  distributionHeader: any[] = DISTRIBUTION_HEADERS;
  schemesHeader: any[] = SCHEMES_HEADERS;
  ROIHeader: any[] = ROI_HEADERS;
  m1Header: any[] = M1_HEADERS;
  m2Header: any[] = M2_HEADERS;
  m3Header: any[] = M3_HEADERS;
  channelTarget: any[] = CHANNEL_TARGET_UPLIFT_ACHIEVEMENT;
  //Table header @input
  projectSettingsc = this.channelTargetHeaders;
  projectSettings = this.channelTargetHeader;
  achivementSettings = this.channelBudgetHeader;
  targetSettings = this.channeUpliftHeader;
  distributiontSettings = this.distributionHeader;
  schemesSettings = this.schemesHeader;
  ROISettings = this.ROIHeader;
  M1OverallSettings = this.m1Header;
  M2OverallSettings = this.m2Header;
  M3OverallSettings = this.m3Header;
  selectedNewRegion: number = 0;
  //Data Variables;

  states: StateModel[];
  channels: ChannelModel[];
  frequency: FrequencyModel[];
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[] = [];
  selectOption = [
    { id: 1, name: "State" },
    { id: 2, name: "Region" },
    { id: 3, name: "M1" },
    { id: 4, name: "M2" },
    { id: 5, name: "M3" },
  ];

  // Date Variable;

  start_date: any;
  end_date: any;

  // Bar Chart
  colors: any[] = COLOR_TITLE;
  POINTS_SUMMARY: TileModel[] = [];
  TARGET_ACHIEVEMENT: TileModel[] = [];
  public TSEDailybarChartLabels7: any[] = [];
  public TSEDialybarChartData7: any[] = [];

  // Dropdown Names

  regionDropdown = "Select Region";
  stateDropdown = "Select State";
  channelDropdown = "Select Channel";
  m1Dropdown = "Select M1";
  m2Dropdown = "Select M2";
  m3Dropdown = "Select M3";
  BrandDropdown = "Brand";
  TmmDropdowm = "TMM";
  startDate: any;
  endDate: any;
  brands: any[];
  // Dropdown Selected Values;

  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedM1: number[] = [];
  selectedM2: number[] = [];
  selectedM3: number[] = [];
  selectedBrand: any;
  resetDropdown: boolean;
  @Input() filterData: FilterModel;
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  region_id: number;
  constructor(
    public routerDataProvider: RouteDataProvider,
    public commonProvider: CommonProvider,
    public datepipe: DatePipe,
    public hubAdminDataProvider: HubAdminDataProvider,
    public generalService: GeneralService,
    public ngxLoader: NgxUiLoaderService,
    public alertService: AlertService,
    private httpClient: CommonHttpServiceService,
    private settingDataService: SettingDataService,
    private toastr: ToastrService,
    private usersService: RegionDataService
  ) {
    this.maxDate = new Date();
  }

  ngOnChanges() {
    console.log(this.filterData);
    this.unintialiseVariables();
    this.data = [];
    this.getKingReport(
      this.filterData.startDate,
      this.filterData.endDate,
      this.filterData.selectedNewRegion,
      this.filterData.state,
      this.filterData.channel,
      this.filterData.m1,
      this.filterData.m2,
      this.filterData.m3,
      0,
      this.filterData.whitelist_date1,
      this.filterData.smonth,
      this.filterData.sdate
    );
  }

  ngOnInit() {
    this.region_id = parseInt(localStorage.getItem("region_id"));
    this.getRegions();
    this.getKingReport(0, 0, this.region_id, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    (this.min = 2019), (this.max = new Date().getFullYear());
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    this.options.reverse();
  }

  averageINR = [
    {
      name: "Average INR Value Earned By The CSM",
      value: "0",
      color: "",
      value1: 0,
    },
  ];

  getKingReport(
    startdate,
    enddate,
    region,
    state,
    channel,
    tse_user_id,
    m2_user_id,
    m3_user_id,
    brand_id,
    whitelist_date,
    month,
    year
  ) {
    this.ngxLoader.start();
    let calendar_year = parseInt(localStorage.getItem("calendar_year"));
    this.httpClient
      .geKingService(
        startdate,
        enddate,
        region,
        state,
        channel,
        tse_user_id,
        m2_user_id,
        m3_user_id,
        brand_id,
        whitelist_date,
        month,
        year,
        calendar_year
      )
      .subscribe(
        (res: any) => {
          this.unintialiseVariables();
          this.ngxLoader.stop();
          this.data = res.data;
          let i = 0;
          let j = 0;
          this.data.points_summary.forEach((element) => {
            let data: TileModel = new TileModel();
            data.color = this.colors[i];
            data.name = element.name;
            data.value = element.value != null ? element.value : 0;
            if (data.name == "Average Points Earned By The User") {
              data.name = "Average Points Earned By The CSM";
              this.averageINR[0].value = "₹ " + data.value * 4;
              this.averageINR[0].color = this.colors[i + 2];
            }
            this.POINTS_SUMMARY.push(data);
            i++;
          });

          this.POINTS_SUMMARY = this.POINTS_SUMMARY.concat(this.averageINR);

          this.data.region_achivement.forEach((element) => {
            let data: TileModel = new TileModel();
            data.color = this.colors[i];
            data.name = element.region_name;
            data.name = element.region_name.replaceAll("POC", "CSM");
            data.name = element.region_name.replaceAll("Outlet", "CSM");
            data.name = element.region_name.replaceAll("User", "CSM");
            data.name = element.region_name.replaceAll("poc", "CSM");
            data.name = element.region_name.replaceAll("outlets", "CSM");
            data.name = element.region_name.replaceAll("outlet", "CSM");
            data.name = element.region_name.replaceAll("user", "CSM");
            data.name = element.region_name.replaceAll(
              "Total value Earned(INR)",
              " Total value Earned (INR)"
            );
            if (element.region_name == "Average Points Earned By The User") {
              data.name = "Average Points Earned By The CSM";
            }
            data.value = element.target_qty;
            this.TARGET_ACHIEVEMENT.push(data);
            i++;
          });

          this.kingService[0].data = this.data.target_vs_achievement[0].target_qty;
          this.kingService[1].data = this.data.target_vs_achievement[0].sales_qty;
          this.kingService[2].data =
            this.data.target_vs_achievement[0].percentage + " %";
          this.kingService[3].data =
            this.data.target_vs_achievement[0].pocs + " %";
          this.kingService[4].data =
            this.data.target_vs_achievement[0].hemix + " %";
          this.kingService[5].data =
            typeof this.data.target_vs_achievement[0].uplift != "undefined" &&
            this.data.target_vs_achievement[0].uplift != null
              ? this.data.target_vs_achievement[0].uplift + " %"
              : "0 %";
          this.kingService[6].data =
          typeof this.data.target_vs_achievement[0].core_uplift != "undefined" &&
          this.data.target_vs_achievement[0].core_uplift != null
          ? this.data.target_vs_achievement[0].core_uplift + " %"
          : "0 %";
          this.kingService[7].data =
          typeof this.data.target_vs_achievement[0].premium_uplift != "undefined" &&
          this.data.target_vs_achievement[0].premium_uplift != null
          ? this.data.target_vs_achievement[0].premium_uplift + " %"
          : "0 %";
          this.kingService[8].data =
          typeof this.data.target_vs_achievement[0].becks_uplift != "undefined" &&
          this.data.target_vs_achievement[0].becks_uplift != null
          ? this.data.target_vs_achievement[0].becks_uplift + " %"
          : "0 %";
          this.kingService[9].data =
          typeof this.data.target_vs_achievement[0].ipb_uplift != "undefined" &&
          this.data.target_vs_achievement[0].ipb_uplift != null
          ? this.data.target_vs_achievement[0].ipb_uplift + " %"
          : "0 %";
          // this.kingService[6].data =
          //   ((typeof this.data.target_vs_achievement[0].roi != "undefined") && (this.data.target_vs_achievement[0].roi != null))
          //     ? this.data.target_vs_achievement[0].roi + " %"
          //     : "0 %";
          this.kingService[6].data = this.data.target_vs_achievement[0].total_points_earned;
          this.kingService[7].data =
            " ₹" + this.data.target_vs_achievement[0].total_value_earned_inr;
          this.kingService[8].data = this.data.target_vs_achievement[0].total_points_budget;
          this.kingService[9].data =
            " ₹ " + this.data.target_vs_achievement[0].total_value_budget_inr;
        },
        (error) => {
          // unauthorized access
          if (error.status == 401 || error.status == 403) {
          } else {
            // this.alertService.error(error.data.message);
            this.toastr.error(`Internal Server Error`, "Dashboard", {
              timeOut: 10000,
              closeButton: true,
              positionClass: "toast-bottom-right",
            });
          }
        }
      );
  }

  dropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "name",
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    enableCheckAll: false,
    limitSelection: 500,
    disabled: true,
  };

  onChangeMonth(item: any) {
    localStorage.setItem("month", item);
  }

  onChangeYear(item: any) {
    localStorage.setItem("year", item);
  }

  regionsData: RegionModel[];
  statesData: StateModel[];
  selectedStates: StateModel[];
  getRegions(): void {
    this.settingDataService.getAllRegion().subscribe(
      (result: any) => {
        this.regionsData = result.data.regions;
        this.getStateList();
        if (typeof this.regionsData != "undefined") {
          if (this.regionsData.length > 0) {
            this.regionsData = this.regionsData.filter(
              (i) => i.id == this.region_id
            );
            this.selectedNewRegion = this.regionsData.find(
              (i) => i.id == this.region_id
            ).id;
          }
        }
        this.regionsData.forEach((ele) => {
          ele.itemName = ele.region_name;
          ele.checked = false;
          ele.name = ele.region_name;
        });
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getStateList(): void {
    this.usersService.getAllStates().subscribe((data) => {
      this.states = data;
      this.dropdown.find((d) => d.id == 2).data = this.states.filter(
        (i) => i.region_id == this.selectedNewRegion
      );
      this.states.forEach((s) => {
        s.name = s.state_name;
        s.id = s.state_code;
      });
    });
  }

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    this.brands = this.commonProvider.brandCategoryList;
    this.dropdown.find((d) => d.id == 3).data = this.channels;
    this.dropdown.find((d) => d.id == 4).data = this.m1Users;
    this.dropdown.find((d) => d.id == 5).data = this.m2Users;
    this.dropdown.find((d) => d.id == 6).data = this.m3Users;
    this.dropdown.find((d) => d.id == 7).data = this.brands;
    let month = localStorage.getItem("month");
    if (typeof month != "undefined") {
      this.smonth = JSON.parse(month);
    } else {
      this.smonth = 0;
    }
    let year = localStorage.getItem("year");
    if (typeof year != "undefined") {
      this.sdate = JSON.parse(year);
    } else {
      this.sdate = 0;
    }
    let states = localStorage.getItem("selectedstates");
    if (typeof states != "undefined") {
      this.dropdown.find((d) => d.id == 2).selected = JSON.parse(states);
    }
    let channels = localStorage.getItem("selectedchannels");
    if (typeof channels != "undefined") {
      this.dropdown.find((d) => d.id == 3).selected = JSON.parse(channels);
    }
  }

  // Service Call

  totwhitelistvsopenratemodule: any;

  // getGeneralReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id, brand_id) {
  //   // StartingLoader;
  //   this.ngxLoader.start();
  //   this.generalService.getGeneralReport(startdate, enddate, region, state, channel, tse_user_id, m2_user_id, m3_user_id)
  //     .subscribe(
  //       (result: any) => {
  //         this.ngxLoader.stop();
  //         if (Object.getOwnPropertyNames(result.data.totwhitelistvsopenratemodule).length !== 0) {
  //           let data = result.data.totwhitelistvsopenratemodule;
  //           this.totwhitelistvsopenratemodule = data;
  //           this.TSEDailybarChartLabels7 = Object.values(result.data.totwhitelistvsopenratemodule).map(e => e['module_name']);
  //           let chart: ChartModel = new ChartModel;
  //           let chart1: ChartModel = new ChartModel;
  //           data.forEach(element => {
  //             chart.data = Object.values(result.data.totwhitelistvsopenratemodule).map(e => e['whitelist_count']);
  //             chart.label = 'Whitelist Count';
  //           });

  //           this.TSEDialybarChartData7.push(chart);
  //         }
  //       },
  //       error => {
  //         // unauthorized access
  //         if (error.status == 401 || error.status == 403) {
  //         } else {
  //           this.alertService.error(error.data.message);
  //         }
  //       }
  //     )
  // }

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        if (event != 0) {
          event.forEach((element) => {
            let data = this.states.filter((s) => s.region_id == element.id);
            this.StatesfilterByRegion.push(...data);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        console.log(this.region);
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        break;
      case 2:
        this.state = this.getList(event, "selectedState");
        localStorage.setItem("selectedstates", JSON.stringify(event));
        break;
      case 3:
        this.channel = this.getList(event, "selectedChannel");
        localStorage.setItem("selectedchannels", JSON.stringify(event));
        console.log(event);
        break;
      case 4:
        this.m1 = this.getList(event, "selectedM1");
        localStorage.setItem("selectedm1s", JSON.stringify(event));
        break;
      case 5:
        // this.selectedM2 = event;
        this.m2 = this.getList(event, "selectedM2");
        localStorage.setItem("selectedm2s", JSON.stringify(event));
        break;
      case 6:
        // this.selectedM3 = event;
        this.m3 = this.getList(event, "selectedM3");
        localStorage.setItem("selectedm3s", JSON.stringify(event));
        break;
    }
    // this.searchByFilter();
  }

  getList(event, mainData) {
    this[mainData] = event;

    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
      console.log(this[mainData]);
    } else {
      g = "";
    }

    return g;
  }

  errorMsg: any = "";
  errorMsg1: any = "";
  searchByFilter() {
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.resetDropdown = false;
    if (this.whitelist_date == 0) {
      this.whitelist_date1 = 0;
    } else {
      this.whitelist_date1 = this.datepipe.transform(
        this.whitelist_date,
        "yyyy-MM-dd"
      );
    }
    if (this.dateRange.length == 0) {
      this.startDate = 0;
      this.endDate = 0;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
    }

    if (this.selectedNewRegion === undefined || this.selectedNewRegion == 0) {
      this.selectedNewRegion = 0;
    }
    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (
      this.selectedChannel === undefined ||
      this.selectedChannel.length == 0
    ) {
      this.channel = "";
    }

    if (this.selectedM1 === undefined || this.selectedM1.length == 0) {
      this.m1 = "";
    }

    if (this.selectedM2 === undefined || this.selectedM2.length == 0) {
      this.m2 = "";
    }

    if (this.selectedM3 === undefined || this.selectedM3.length == 0) {
      this.m3 = "";
    }
    if (this.selectedBrand === undefined) {
      this.selectedBrand = 0;
    }

    if (this.smonth == "0" && this.sdate == "0") {
      this.errorMsg = "Please select year";
      this.errorMsg1 = "Please select month";

      // if(parseInt(this.smonth) !=0) {
      //   if(parseInt(this.sdate) !=0) {
      //       this.getGeneralReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3,this.whitelist_date1,this.smonth,this.sdate);
      //   }
      //   else{
      //     this.errorMsg = "Please select year";
      //     this.errorMsg1 = "Please select month";
      //   }
      // }
    } else {
      if (typeof this.smonth != "undefined") {
        if (parseInt(this.smonth) != 0) {
          if (parseInt(this.sdate) != 0) {
            this.getKingReport(
              this.startDate,
              this.endDate,
              this.selectedNewRegion,
              this.state,
              this.channel,
              this.m1,
              this.m2,
              this.m3,
              this.selectedBrand,
              this.whitelist_date1,
              this.smonth,
              this.sdate
            );
          } else {
            this.errorMsg = "Please select year";
          }
        }
      }

      if (typeof this.sdate != "undefined") {
        if (parseInt(this.sdate) != 0) {
          if (parseInt(this.smonth) != 0) {
            this.getKingReport(
              this.startDate,
              this.endDate,
              this.selectedNewRegion,
              this.state,
              this.channel,
              this.m1,
              this.m2,
              this.m3,
              this.selectedBrand,
              this.whitelist_date1,
              this.smonth,
              this.sdate
            );
          } else {
            this.errorMsg1 = "Please select month";
          }
        }
      }
    }
    // if(typeof this.smonth !="undefined") {
    //   if(parseInt(this.smonth) !=0) {
    //     if(parseInt(this.sdate) !=0) {
    //       this.unintialiseVariables();
    //       this.getKingReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3, this.selectedBrand,this.whitelist_date1,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg = "Please select year";
    //     }
    //   }
    // }

    // if(typeof this.sdate !="undefined") {
    //   if(parseInt(this.sdate) !=0) {
    //     if(parseInt(this.smonth) !=0) {
    //       this.unintialiseVariables();
    //       this.getKingReport(this.startDate, this.endDate,this.region, this.state, this.channel, this.m1, this.m2, this.m3, this.selectedBrand,this.whitelist_date1,this.smonth,this.sdate);
    //     }
    //     else{
    //       this.errorMsg1 = "Please select month";
    //     }
    //   }
    // }
  }

  unintialiseVariables() {
    this.POINTS_SUMMARY = [];
    this.TARGET_ACHIEVEMENT = [];
  }

  public convetToPDF() {
    this.ngxLoader.startBackground();
    var data = document.getElementById("Program-Overview");
    $(data).prepend(
      "<p id='gr' style='font-size:40px;margin:40px 0px 60px 0px;'>King Service</p> "
    );
    html2canvas(data)
      .then((canvas) => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jsPDF.jsPDF("p", "mm", "a4"); // A4 size page of PDF
        var position = 0;
        heightLeft -= pageHeight;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("content_report.pdf");
        this.ngxLoader.stopBackground();
      })
      .then(() => {
        $("#gr").remove();
      });
  }
  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }
}
