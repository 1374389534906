import { NgModule } from "@angular/core";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { CommonModule, DatePipe } from "@angular/common";
import { BsDatepickerModule, TooltipModule } from "ngx-bootstrap";
import { SharedModule } from "../shared/shared.module";
import { DataTableModule } from "angular2-datatable";
import { BreadcrumbsModule } from "ng6-breadcrumbs";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SimpleLayoutComponent } from "./layout/simple-layout.component";
import { BackendLayoutComponent } from "./layout/backend-layout.component";
import { AlertComponent } from "./alert/alert.component";
import { GeneralComponent } from "./general/general.component";
import { KingserviceComponent } from "./kingservice/kingservice.component";
import { WeeklyChallengesComponent } from "./weekly-challenges/weekly-challenges.component";
import { CommonProvider } from "../shared/Providers/Shared/CommonProvider";
import { ColorTileComponent } from "./SharedComponents/color-tile/color-tile.component";
import { WhiteTileComponent } from "./SharedComponents/white-tile/white-tile.component";
import { AverageTab1Component } from "./SharedComponents/average-tab1/average-tab1.component";
import { BarChartComponent } from "./SharedComponents/bar-chart/bar-chart.component";
import { CommonDropdownComponent } from "./SharedComponents/common-dropdown/common-dropdown.component";
import { ChartistModule } from "ng-chartist";
import { ProgressBrComponent } from "./SharedComponents/progress-br/progress-br.component";
import { GreyTileComponent } from "./SharedComponents/grey-tile/grey-tile.component";
import { CrownCollectionComponent } from "./crown-collection/crown-collection.component";
import { ContentandLearningComponent } from "./contentand-learning/contentand-learning.component";
import { VisiblityBrandComponent } from "./visiblity-brand/visiblity-brand.component";
import { VisiblityCoolerComponent } from "./visiblity-cooler/visiblity-cooler.component";
import { GeneralTableComponent } from "./SharedComponents/general-table/general-table.component";
import { MenuProgramProvider } from "../admin/Providers/MenuProgramProvider";
import { DailyReportComponent } from "./download-reports/daily-report/daily-report.component";
import { WeeklyReportComponent } from "./download-reports/weekly-report/weekly-report.component";
import { MonthlyReportComponent } from "./download-reports/monthly-report/monthly-report.component";
import { ExportExcelService } from "./Services/export-excel.service";
import { FirebaseComponent } from "./download-reports/firebase/firebase.component";
import { MysteryshopperReportComponent } from "./mysteryshopper-report/mysteryshopper-report.component";
import { MysteryQcComponent } from "./mysteryoutlet-leveldetails/mystery-qc.component";
import { TabsModule } from "ngx-bootstrap";
import { EngagementComponent } from "./engagement/engagement.component";
import { ReportingDashboardComponent } from "./reporting-dashboard/reporting-dashboard.component";
import { MenuProgramsComponent } from "./menu-programs/menu-programs.component";
import { LoaderComponent } from './SharedComponents/loader/loader.component';
import { UpliftReportComponent } from './download-reports/uplift-report/uplift-report.component';

@NgModule({
  imports: [
    DashboardRoutingModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    SharedModule.forRoot(),
    DataTableModule,
    BreadcrumbsModule,
    Ng2SearchPipeModule,
    ChartistModule,
    TabsModule.forRoot(),
  ],
  declarations: [
    BackendLayoutComponent,
    SimpleLayoutComponent,
    AlertComponent,
    GeneralComponent,
    KingserviceComponent,
    WeeklyChallengesComponent,
    ContentandLearningComponent,
    ColorTileComponent,
    WhiteTileComponent,
    AverageTab1Component,
    BarChartComponent,
    CommonDropdownComponent,
    ProgressBrComponent,
    GreyTileComponent,
    CrownCollectionComponent,
    VisiblityBrandComponent,
    VisiblityCoolerComponent,
    GeneralTableComponent,
    // EntryComponent,
    // MenuProgramOverviewComponent,
    DailyReportComponent,
    WeeklyReportComponent,
    MonthlyReportComponent,
    FirebaseComponent,
    MysteryshopperReportComponent,
    MysteryQcComponent,
    EngagementComponent,
    ReportingDashboardComponent,
    MenuProgramsComponent,
    LoaderComponent,
    UpliftReportComponent,
  ],
  providers: [
    DatePipe,
    CommonProvider,
    MenuProgramProvider,
    ExportExcelService
  ],
})
export class DashboardModule {}
