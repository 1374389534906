import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Angular2Csv } from "angular2-csv";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  COLOR_TITLE,
  MENU_DROPDOWN,
} from "src/app/admin/Models/general-headings.model";
import { LoyaltyTargetProvider } from "src/app/admin/Providers/LoyaltyTargetProvider";
import { MenuProgramProvider } from "src/app/admin/Providers/MenuProgramProvider";
import { ChannelModel, RegionModel } from "src/app/model/contract/channel";
import {
  MenuTemplate,
  MenuBrandModel,
  StateModel,
  MenuTemplateRequestModel,
  MenuTransactionModel,
} from "src/app/model/contract/menu-program";
import { GlobalService } from "src/app/model/global.service";
import { RegionDataService } from "src/app/model/region-data.service";
import { HubAdminDataProvider } from "src/app/shared/Providers/ModuleDataProvider/HubAdminDataProviders";
import { RouteDataProvider } from "src/app/shared/Providers/RouterProvider/RouteDataProvider.provider";
import { CommonProvider } from "src/app/shared/Providers/Shared/CommonProvider";
import { HierarchyProvider } from "src/app/shared/Providers/Shared/HierarchyProvider";
import { FilterModel } from "../Models/filter-data";
@Component({
  selector: "app-menu-programs",
  templateUrl: "./menu-programs.component.html",
  styleUrls: ["./menu-programs.component.scss"],
})
export class MenuProgramsComponent implements OnInit {
  isSearch: boolean;
  dropdownList = [];
  statusDropdown = [];
  dropdownSettings = {};
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  templates: MenuTemplate[];
  requestTransactions: MenuTransactionModel[];
  siteUrl: any;
  brands: MenuBrandModel[];
  states: StateModel[];
  requests: MenuTemplateRequestModel[];
  allrequests: MenuTemplateRequestModel[];
  channels: ChannelModel[];
  searchText: any;
  requestSendToAgency: MenuTemplateRequestModel[];
  requestUpdatedByAgency: MenuTemplateRequestModel[];
  requestApproved: MenuTemplateRequestModel[];
  colors: any[] = COLOR_TITLE;
  dateRange: any = [];
  searchByBrandData: number;
  searchByTemplateData: number;
  searchByStatus: any;
  date1: any;
  date2: any;
  @Input() model: MenuTemplateRequestModel = new MenuTemplateRequestModel();
  assignUser: number;
  reassignUser: number;
  error: { isError: boolean; errorMessage: string };
  disableAssign: boolean = false;
  disableReAssign: boolean = false;
  regionList: RegionModel[];
  searchByRegion: any;
  searchByState: any;
  modalRef2: BsModalRef;
  totalCost: number = 0;
  regions: RegionModel[];
  m1Users: any[];
  m2Users: any[];
  m3Users: any[];
  StatesfilterByRegion: StateModel[];
  selectOption = [
    { id: 1, name: "State" },
    { id: 2, name: "Region" },
    { id: 3, name: "M1" },
    { id: 4, name: "M2" },
    { id: 5, name: "M3" },
  ];

  // Date Variable;

  start_date: any;
  end_date: any;
  dropdown: any[] = MENU_DROPDOWN;
  startDate: any;
  endDate: any;
  searchRange;
  selectedRegion: number[] = [];
  selectedState: number[] = [];
  selectedChannel: number[] = [];
  selectedBrand: number[] = [];
  selectedTemplate: number[] = [];
  selectedStatus: number[] = [];
  region = "";
  state = "";
  channel = "";
  m1 = "";
  m2 = "";
  m3 = "";
  event = [];
  resetDropdown: boolean;
  min: number;
  max: number;
  options: Array<any> = [];
  errorMsg1: string;
  errorMsg: string;
  spinner: boolean = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public routerDataProvider: RouteDataProvider,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public ngxLoader: NgxUiLoaderService,
    public commonProvider: CommonProvider,
    public geoHerirarchProvider: HierarchyProvider,
    public targetProvider: LoyaltyTargetProvider,
    private hubAdminDataProvider: HubAdminDataProvider,
    private teamLeaderProvider: MenuProgramProvider,
    public datepipe: DatePipe,
    public usersService: RegionDataService,
    public globalService: GlobalService
  ) {
    this.siteUrl = this.globalService.apiHost;
  }

  @Input() filterData: FilterModel;

  ngOnChanges() {}

  ngOnInit() {
    (this.min = 2019), (this.max = new Date().getFullYear());
    let options = "<option>Select Year</option>";
    for (var i = this.min; i <= this.max; i++) {
      options += '<option value="' + i + '">' + i + "</option>";
      this.options.push(i);
    }
    console.log(this.options);
    this.options.reverse();
    this.getMenuTemplateRequestList();
    this.searchByStatus = "";
    this.statusDropdown = [
      // { item_id: 1, item_text: 'Pending Review By TSE' },
      { id: 2, name: "Sent to Agency" },
      { id: 3, name: "Updated by Agency" },
      { id: 4, name: "Approved" },
      // { item_id: 5, item_text: 'Rejected' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  menuProgram = [
    {
      id: 1,
      name: "Sent to Agency",
      color: this.colors[0],
      points: 0,
    },
    {
      id: 2,
      name: "Updated by Agency",
      color: this.colors[1],
      points: 0,
    },
    {
      id: 3,
      name: "Approved",
      color: this.colors[2],
      points: 0,
    },
    {
      id: 4,
      name: "Total Cost",
      color: this.colors[1],
      points: 0,
    },
  ];

  ngDoCheck() {
    this.states = this.commonProvider.states;
    this.m1Users = this.commonProvider.tseUsers;
    this.m2Users = this.commonProvider.m2Users;
    this.m3Users = this.commonProvider.m3Users;
    this.channels = this.commonProvider.channelList;
    this.regions = this.commonProvider.regionList;
    this.templates = this.teamLeaderProvider.templates;
    this.brands = this.teamLeaderProvider.brands;
    this.dropdown.find((d) => d.id == 1).data = this.regions;
    this.dropdown.find((d) => d.id == 7).data = this.brands;
    this.dropdown.find((d) => d.id == 8).data = this.templates;
    this.dropdown.find((d) => d.id == 9).data = this.statusDropdown;
  }

  onDateSelection(item) {
    this.teamLeaderProvider.getMenuTemplateRequestList();
    if (item != null) {
      this.ngxLoader.startBackground();
      setTimeout(() => {
        this.ngxLoader.stopBackground(); // stop foreground spinner of the master loader with 'default' taskId
      }, 1000);
      this.isSearch = true;
      this.date1 = this.datepipe.transform(item[0], "yyyy-MM-dd");
      this.date2 = this.datepipe.transform(item[1], "yyyy-MM-dd");
      this.teamLeaderProvider.getDateBasedRecords(this.date1, this.date2);
    }
  }

  searchData() {
    if (!this.isSearch) {
      this.teamLeaderProvider.searchMenuRecords(
        this.searchByBrandData,
        this.searchByTemplateData,
        this.searchByStatus,
        this.searchByRegion,
        this.searchByState
      );
    } else {
      this.teamLeaderProvider.searchMenuRecordsWithDate(
        this.searchByBrandData,
        this.searchByTemplateData,
        this.searchByStatus,
        this.date1,
        this.date2,
        this.searchByRegion,
        this.searchByState
      );
    }
  }

  moveToIndivialEntry(entries: any) {
    this.router.navigate(["/report-admin/menu-program-report/", entries.id]);
  }

  // Download Template
  private _csvdata = [];
  _downloadSubmitted: boolean;

  public downloadTemplate() {
    this.ngxLoader.startBackground();
    this._downloadSubmitted = true;
    this._csvdata = [];
    let filename: string = "Menu_Report";
    // if (this.startdate) {
    //   filename = filename + '_' + this.startdate + '_' + this.enddate;
    // } else {
    //   filename = filename;
    // }
    let options = {
      showLabels: true,
      headers: [
        "Brand Name",
        "Template Name",
        "Region",
        "State",
        "Outlet Code",
        "Outlet Name",
        "Copies",
        "No. of Page",
        "Cost Per Page",
        "Total Cost",
        "Status",
        "Updated Date",
        "TSE Name",
        "Assign Agent Name",
      ],
    };

    for (let i = 0; i < this.requests.length; i++) {
      let item = this.requests[i];
      let dt = [null, null];
      console.log(item);
      console.log(dt);
      this._csvdata.push({
        menu_brand_name: item.menu_brand_name,
        menu_template_name: item.menu_template_name,
        region_name: item.region_name,
        state_name: item.state_name,
        ro_code: item.ro_code,
        ro_name: item.ro_name,
        no_of_copies: item.no_of_copies,
        no_of_pages: item.no_of_pages,
        cost_per_page: item.cost_per_page,
        total_cost: item.total_cost,
        request_status: item.request_status,
        request_timestamp: item.request_timestamp,
        tse_name: item.tse_name,
        assigned_user_name: item.assigned_user_name,
      });
    }
    new Angular2Csv(this._csvdata, filename, options);
    this.ngxLoader.stopBackground();
    this._downloadSubmitted = false;
  }

  //Dummy

  // Service Call
  m1FilterByRegion = [];
  m2FilterByRegion = [];
  m3FilterByRegion = [];

  selectedValueCheck(event, filter): void {
    // console.log("general:", event, filter);
    this.event = event;
    console.log(event);

    switch (filter) {
      case 1:
        this.StatesfilterByRegion = [];
        this.m1FilterByRegion = [];
        this.m2FilterByRegion = [];
        this.m3FilterByRegion = [];
        if (event != 0) {
          event.forEach((element) => {
            let data = this.states.filter((s) => s.region_id == element.id);
            this.StatesfilterByRegion.push(...data);
          });
        }
        this.region = this.getList(event, "selectedRegion");
        this.dropdown.find((d) => d.id == 2).data = this.StatesfilterByRegion;
        break;
      case 2:
        this.state = this.getList(event, "selectedState");
        break;
      case 7:
        this.m1 = this.getList(event, "selectedBrand");
        break;
      case 8:
        this.m2 = this.getList(event, "selectedTemplate");
        break;
      case 9:
        this.m3 = this.getList(event, "selectedStatus");
        break;
    }
    // this.searchByFilter();
    // this.searchByFilter();
  }

  getList(event, mainData) {
    this[mainData] = event;
    var z = [];
    var g;
    if (event.length != 0) {
      event.forEach((element) => {
        z.push(element.id);
      });
      g = z.join();
      this[mainData] = z;
      console.log(this[mainData]);
    } else {
      g = "";
    }

    return g;
  }

  getMenuTemplateRequestList(): void {
    let calendar_month = parseInt(localStorage.getItem("calendar_year"));
    this.usersService.getAllTemplateMenuRequest(calendar_month).subscribe((data) => {
      this.requests = data;
      this.allrequests = data;
      this.totalCost = this.requests
        .filter((r) => r.total_cost && r.internal_status == 4)
        .reduce((a, b) => a + b.total_cost || 0, 0);
      if (this.requests.length > 0) {
        this.requests.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
        this.requests.forEach((r) => {
          r.boldstatus = false;
          if (
            new Date(r.request_timestamp) <=
              new Date(Date.now() - 4 * 24 * 60 * 60 * 1000) &&
            r.internal_status == 2
          ) {
            r.boldstatus = true;
          }
        });
      }

      if (typeof this.allrequests != "undefined") {
        if (this.allrequests.length > 0) {
          this.requestSendToAgency = this.allrequests.filter(
            (r) => r.internal_status == 2
          );
          this.requestUpdatedByAgency = this.allrequests.filter(
            (r) => r.internal_status == 3
          );
          this.requestApproved = this.allrequests.filter(
            (r) => r.internal_status == 4
          );
          this.menuProgram.find(
            (i) => i.id == 1
          ).points = this.requestSendToAgency.length;
          this.menuProgram.find(
            (i) => i.id == 2
          ).points = this.requestUpdatedByAgency.length;
          this.menuProgram.find(
            (i) => i.id == 3
          ).points = this.requestApproved.length;
          this.menuProgram.find((i) => i.id == 4).points = this.totalCost;
        } else {
          this.requestSendToAgency = [];
          this.requestUpdatedByAgency = [];
          this.requestApproved = [];
          this.allrequests = [];
        }
      }
    });
  }

  searchByFilter() {
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.resetDropdown = false;
    if (this.dateRange == []) {
      this.startDate = 0;
      this.endDate = 0;
      this.isSearch = false;
    } else {
      this.startDate = this.datepipe.transform(this.dateRange[0], "yyyy-MM-dd");
      this.endDate = this.datepipe.transform(this.dateRange[1], "yyyy-MM-dd");
      this.isSearch = true;
    }

    if (this.selectedRegion === undefined || this.selectedRegion.length == 0) {
      this.region = "";
    }

    if (this.selectedState === undefined || this.selectedState.length == 0) {
      this.state = "";
    }

    if (this.selectedBrand === undefined || this.selectedBrand.length == 0) {
      this.m1 = "";
    }

    if (
      this.selectedTemplate === undefined ||
      this.selectedTemplate.length == 0
    ) {
      this.m2 = "";
    }

    if (this.selectedStatus === undefined || this.selectedStatus.length == 0) {
      this.m3 = "";
    }

    if (typeof this.searchRange != "undefined") {
      if (this.searchRange.length > 0) {
        this.date1 = this.datepipe.transform(this.searchRange[0], "yyyy-MM-dd");
        this.date2 = this.datepipe.transform(this.searchRange[1], "yyyy-MM-dd");
        this.isSearch = true;
      }
    }

    if (this.isSearch) {
      this.searchMenuRecords(
        this.selectedBrand,
        this.selectedTemplate,
        this.selectedStatus,
        this.selectedRegion,
        this.selectedState
      );
    } else {
      this.searchMenuRecordsWithDate(
        this.selectedBrand,
        this.selectedTemplate,
        this.selectedStatus,
        this.date1,
        this.date2,
        this.selectedRegion,
        this.selectedState
      );
    }
  }

  resetFilter() {
    this.resetDropdown = true;
    this.dateRange = [];
    location.reload();
    console.log("Reset");
  }

  openModalWithClass(template: TemplateRef<any>, data: any) {
    this.getMenuRequestTransactions(data.id);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }

  getMenuRequestTransactions(id) {
    this.ngxLoader.start();
    this.usersService
      .getAllTemplateMenuRequestTransaction(id)
      .subscribe((data) => {
        this.requestTransactions = data;
        if (this.requestTransactions.length > 0) {
          this.requestTransactions.forEach((f) => {
            this.ngxLoader.stop();
            if (f.template_logo != null) {
              let path = f.template_logo.split("/");
              console.log(path);
              f.logo_name = path[path.length - 1];

              if (f.template_images.length > 0) {
                f.template_images.forEach((i) => {
                  let path1 = f.template_logo.split("/");
                  console.log(path1);
                  let path2 = i.menu_template_image.split("/");
                  i.folder_name = path2[path2.length - 2];
                  console.log(i.folder_name);
                  f.menu_folder_name = i.folder_name;
                  i.image_name = path1[path1.length - 1];
                });
              }
            }
          });
        }
      });
  }

  searchMenuRecords(brandId, templateName, internalStatus, region, state) {
    if (typeof brandId != "undefined") {
      if (brandId != null && brandId != "") {
        this.requests = this.allrequests.filter((f) => {
          return brandId.includes(f.menu_brand_id);
        });
        console.log(this.requests);
      }
    }

    if (typeof templateName != "undefined") {
      if (templateName != null && templateName != "") {
        this.requests = this.allrequests.filter((f) => {
          return templateName.includes(f.template_id);
        });
      }
    }

    if (internalStatus != "") {
      this.requests = this.allrequests.filter((f) => {
        return internalStatus.includes(f.internal_status);
      });
    }

    if (typeof region != "undefined") {
      if (region != null && region != "") {
        this.requests = this.allrequests.filter((f) => {
          return region.includes(f.region_id);
        });
      }
    }

    if (typeof state != "undefined") {
      if (state != null && state != "") {
        this.requests = this.allrequests.filter((f) => {
          return state.includes(f.state_id);
        });
      }
    }
  }

  searchMenuRecordsWithDate(
    brandId,
    templateName,
    internalStatus,
    date1,
    date2,
    region,
    state
  ) {
    let tempDate = new Date(date2);
    console.log("here");
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    if (typeof brandId != "undefined" && brandId != "") {
      if (brandId != 0) {
        this.requests = this.requests.filter((m) => {
          return (
            brandId.includes(m.menu_brand_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      }
    }

    if (typeof templateName != "undefined") {
      if (templateName != 0 && templateName != "") {
        this.requests = this.requests.filter((m) => {
          return (
            templateName.includes(m.template_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      }
    }

    if (internalStatus != "") {
      this.requests = this.requests.filter((m) => {
        return (
          internalStatus.includes(m.internal_status) &&
          new Date(m.request_timestamp) >= new Date(date1) &&
          new Date(m.request_timestamp) <= date2
        );
      });
    }

    if (typeof region != "undefined") {
      if (region != 0 && region != "") {
        this.requests = this.requests.filter((m) => {
          return (
            region.includes(m.region_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      }
    }

    if (typeof state != "undefined") {
      if (state != 0 && state != "") {
        this.requests = this.requests.filter((m) => {
          return (
            state.includes(m.state_id) &&
            new Date(m.request_timestamp) >= new Date(date1) &&
            new Date(m.request_timestamp) <= date2
          );
        });
      }
    }
  }

  getDateBasedRecords(date1, date2) {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.requests = this.requests.filter(
      (m) =>
        new Date(m.request_timestamp) >= new Date(date1) &&
        new Date(m.request_timestamp) <= date2
    );
  }
}
